/* src/App.css */



:root {

  --gradient: linear-gradient(

    90deg,

    #ff8c00, #dda0dd, #9370db, #ff8c00, #8a2be2, #ffa500, #ba55d3, #dda0dd, #ff8c00, #9370db,

    #ff8c00, #ba55d3, #8a2be2, #ffa500, #9370db, #dda0dd, #ff8c00, #ba55d3, #8a2be2, #ff8c00,

    #dda0dd, #ff8c00, #9370db, #ffa500, #8a2be2, #ff8c00, #ba55d3, #dda0dd, #9370db, #ff8c00,

    #ffa500, #8a2be2, #ff8c00, #ba55d3, #dda0dd, #9370db, #ff8c00, #ffa500, #8a2be2, #9370db,

    #dda0dd, #ff8c00, #ba55d3, #ff8c00, #8a2be2, #9370db, #dda0dd, #ffa500, #ff8c00, #ba55d3,

    #ff8c00, #9370db, #8a2be2, #ffa500, #dda0dd, #ff8c00, #9370db, #8a2be2, #ff8c00, #ba55d3,

    #dda0dd, #ff8c00, #9370db, #ffa500, #8a2be2, #ff8c00, #ba55d3, #dda0dd, #9370db, #ff8c00,

    #ffa500, #8a2be2, #ff8c00, #ba55d3, #dda0dd, #9370db, #ff8c00, #ffa500, #8a2be2, #9370db

  );

  --purple: #9370db; /* Extracted purple from logo */

  --orange: #ff8c00; /* Extracted orange from logo */

}



@media (max-width: 768px) {

  :root {

    --gradient: linear-gradient(

      90deg,

      #ff8c00, #dda0dd, #9370db, #ff8c00, #8a2be2, #ffa500, #ba55d3, #dda0dd, #ff8c00, #9370db,

      #ff8c00, #ba55d3, #8a2be2, #ffa500, #9370db, #dda0dd, #ff8c00, #ba55d3, #8a2be2, #ff8c00,

      #dda0dd, #ff8c00, #9370db, #ffa500, #8a2be2, #ff8c00, #ba55d3, #dda0dd, #9370db, #ff8c00,

      #ffa500, #8a2be2, #ff8c00, #ba55d3, #dda0dd, #9370db, #ff8c00, #ffa500, #8a2be2, #9370db

    );

  }

}



body {

  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;

  background: var(--gradient);

  background-size: 300% 300%;

  animation: gradientAnimation 120s linear infinite;

  overflow-x: hidden; /* Prevent horizontal scrolling */

  font-size: 102.5%; /* Increase font size by 2.5% */

}



@keyframes gradientAnimation {

  0% {

    background-position: 100% 50%;

  }

  100% {

    background-position: 0% 50%;

  }

}



.App {

  text-align: center;

}



.App-logo {

  height: 41vmin; /* Increase by 2.5% */

  pointer-events: none;

  z-index: 10;

}



@media (prefers-reduced-motion: no-preference) {

  .App-logo {

    animation: App-logo-spin infinite 20s linear;

  }

}



.App-header {

  background-color: #282c34;

  min-height: 5.125vh; /* Increase by 2.5% */

  display: flex;

  align-items: center;

  justify-content: space-between;

  padding: 10.25px 20.5px; /* Increase by 2.5% */

  color: white;

  z-index: 10;

}



.logo-container {

  display: flex;

  align-items: center;

}



.logo {

  height: 5vh;

  margin-right: 10px;

}

@media (max-width: 768px) {

  .logo {

    height: 6.5vh; /* Adjust size for mobile */

  }



  .App-header .logo-container {

    justify-content: center;

  }



  .App-header .logo-container h1 {

    font-size: 18px; /* Adjust size as needed for mobile */

  }

}



.App-header h1 {

  margin: 0;

  font-size: 24.6px; /* Increase by 2.5% */

}



.App-header nav {

  position: relative;

}



.App-header nav .menu-toggle {

  display: none;

  background: none;

  border: none;

  color: white;

  font-size: 24.6px; /* Increase by 2.5% */

  cursor: pointer;

}



.App-header nav ul {

  list-style: none;

  display: flex;

  gap: 20.5px; /* Increase by 2.5% */

  margin: 0;

  padding: 0;

}



.App-header nav ul li {

  margin: 0;

}



.App-header nav ul li a {

  color: white;

  text-decoration: none;

  padding: 5.125px 10.25px; /* Increase by 2.5% */

  border-radius: 10.25px; /* Increase by 2.5% */

  transition: background-color 0.3s, color 0.3s;

}



.App-header nav ul li a:hover {

  background-color: #8f1cb3;

  color: #ff8a2a;

  border: 1px solid #8f1cb3;

}



.App-header nav ul.nav-links.open {

  display: flex;

}



@media (max-width: 768px) {

  .App-header nav .menu-toggle {

    display: block;

  }



  .App-header nav ul {

    display: none;

    flex-direction: column;

    background-color: #282c34;

    position: absolute;

    top: 51.25px; /* Adjusted to make the dropdown appear lower */

    right: 0;

    width: 205px; /* Increase by 2.5% */

    padding: 10.25px; /* Increase by 2.5% */

    border-radius: 10.25px; /* Increase by 2.5% */

    z-index: 100; /* Ensure it renders above the page-box */

  }



  .App-header nav ul.nav-links.open {

    display: flex;

  }

}



.App-link {

  color: #61dafb;

}



.App-content {

  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;

  min-height: 92.25vh; /* Increase by 2.5% */

  z-index: 10;

}



.rights-reserved {

  position: fixed;

  bottom: 10.25px; 

  right: 10.25px; 

  font-size: 12.3px; 

  font-weight: bold;

  z-index: 1;

}



.page-box {

  text-align: left;

  margin: 20.5px; 

  padding: 20.5px; 

  border: 5px solid var(--purple); 

  background-color: white;

  border-radius: 0px; 

  box-sizing: border-box;

  width: 80%;

  max-width: 820px; /* Increase by 2.5% */

  z-index: 10;

  animation: borderColorTransition 5s ease-in-out infinite;

}

.blog-post h3 {
  font-size: 1.5em;
  color: #333;
}

.blog-page-box {
  text-align: left;

  position: relative;

  margin: 20px auto;

  padding: 20px; 

  border: 5px solid var(--purple); 

  background-color: white;

  border-radius: 0px; 

  box-sizing: border-box;

  width: 80%;

  max-width: 1100px; /* Increase by 2.5% */

  z-index: 10;

  animation: borderColorTransition 5s ease-in-out infinite;
  word-wrap: break-word; /* Break long words */
  overflow-wrap: break-word; /* Also handle word breaking for long words */
}

.blog-page-box p {
  font-size: 1.1em;
  color: #666;
  margin-bottom: 15px; /* Space between paragraphs */
  text-indent: 20px; /* Optional: Add indentation for first line */
  white-space: normal; /* Ensure the text wraps normally */
}


.contact-form {

  display: flex;

  flex-direction: column;

  gap: 10.25px; /* Increase by 2.5% */

  z-index: 10;

}



.form-group {

  display: flex;

  flex-direction: column;

}

.collapsible-section {
  margin: 20px 0;
  border-bottom: 1px solid #ccc;
}

.collapsible-title {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.collapsible-title h3 {
  margin: 0;
}

.arrow {
  font-size: 20px;
  transition: transform 0.3s;
}

.arrow.open {
  transform: rotate(180deg);
}

.form-group label {

  font-weight: bold;

}



.form-group input,

.form-group select,

.form-group textarea {

  padding: 10.25px; 

  border-radius: 5.125px; 

  border: 1px solid #ccc;

}



.form-group textarea {

  resize: vertical;

}



button {

  padding: 10.25px 20.5px; 

  border: none;

  border-radius: 5.125px; 

  background-color: #8f1cb3;

  color: white;

  font-weight: bold;

  cursor: pointer;

  z-index: 10;

  font-size: 102.5%; 

}



button:hover {

  background-color: #ff8a2a;

}



.plain-link {

  color: inherit;

  text-decoration: none;

  cursor: pointer;

  font-weight: bold;

}





.home-box-container {

  display: flex;

  flex-wrap: wrap;

  justify-content: center;

  gap: 20.5px; 

  padding: 20.5px; 

  align-items: stretch; 

  z-index: 10;

}



.home-box {

  flex: 1 1 30.75%; 

  border: 5px solid var(--purple); 

  background-color: white;

  padding: 20.5px; 

  margin: 10.25px; 

  border-radius: 0px; 

  box-sizing: border-box;

  text-align: left;

  transition: background-color 0.3s ease;

  cursor: pointer;

  display: flex;

  flex-direction: column;

  justify-content: flex-start;

  z-index: 10;

  animation: borderColorTransition 5s ease-in-out infinite;

}



.home-box:hover {

  background-color: #fbcdff; 

}



.home-box h2 {

  margin-top: 0;

  font-size: 102.5%; 

}



.home-box p {

  flex-grow: 1;

  margin-bottom: 10.25px; 

  font-size: 102.5%; 

}



@media (max-width: 1200px) {

  .home-box {

    flex: 1 1 46.125%; 

  }

}



@media (max-width: 768px) {

  .home-box {

    flex: 1 1 102.5%; 

  }

}





.box-container {

  display: flex;

  flex-wrap: wrap;

  justify-content: center;

  gap: 20.5px; 

  padding: 20.5px; 

  z-index: 10;

}



.box {

  border: 5px solid var(--purple); 

  background-color: white;

  padding: 20.5px; 

  margin: 10.25px; 

  border-radius: 0px; 

  width: 307.5px; 

  height: 307.5px; 

  box-sizing: border-box;

  text-align: left;

  transition: background-color 0.3s ease;

  cursor: pointer;

  z-index: 10;

  animation: borderColorTransition 5s ease-in-out infinite;

}



.box:hover {

  background-color: #fbcdff; 

}



@media (max-width: 1200px) {

  .box {

    width: 307.5px; 

    height: 307.5px; 

  }

}



@media (max-width: 768px) {

  .box {

    width: 102.5%; 

    height: auto;

  }

}



.services-container {

  display: flex;

  justify-content: space-around;

  align-items: flex-start;

  width: 100%;

  max-width: 1230px; 

  margin: 20.5px auto; 

}



@media (max-width: 768px) {

  .services-container {

    flex-direction: column;

    align-items: center;

  }

}





.button-container {

  display: flex;

  justify-content: center;

  gap: 10.25px; 

  margin-top: 20.5px; 

}



@keyframes borderColorTransition {

  0% {

    border-color: var(--purple);

  }

  50% {

    border-color: var(--orange);

  }

  100% {

    border-color: var(--purple);

  }

}



.box, .home-box, .page-box {

  border: 5px solid var(--purple); 

  animation: borderColorTransition 5s ease-in-out infinite;

  border-radius: 0px; 

}

.faq-item {

  border-bottom: 1px solid #ccc;

  padding: 10px 0;

  cursor: pointer;

}



.faq-question {

  display: flex;

  align-items: center;

  justify-content: space-between;

}



.faq-question h3 {

  margin: 0;

  flex: 1;

  text-align: center;

}



.arrow {

  font-size: 20px;

  transition: transform 0.3s;

}



.arrow.open {

  transform: rotate(180deg);

}



.faq-answer {

  padding: 10px 0;

  color: #000000;

}

