/* src/index.css */

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  box-sizing: border-box;
  overflow: hidden; /* Prevent scrolling */
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
}
